<template>
  <div class="d-flex justify-center align-center">
    <!-- 搜尋框 -->
    <span>{{ props.label +'：' }} </span>

    <v-text-field
      v-model="search"
      density="compact"
      label="Search"
      variant="solo-filled"
      flat
      hide-details
      single-line
      class="searchInput"
      @change="getSearch"
    />
  </div>
</template>

<script setup>
const search = ref('')
const props = defineProps({
  submitKey: {
    type: String,
    default: ''
  },

  label: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['inputFn'])
const getSearch = () => {
  emit('inputFn', {
    submitKey: props.submitKey,
    selectedValue: search.value
  })
}
</script>

  <style lang="scss" scoped>
  #searchInput{
    width: auto;
  }

  </style>
