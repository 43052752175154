<template>
  <div id="blacklist">
    <SetupTitle name="黑名單管理" />
    <div class="setupMain">
      <v-tabs v-model="tab" color="#2F88FF">
        <v-tab value="blacklist" @click="switchTab">
          當前黑名單
        </v-tab>
        <v-tab value="history" @click="switchTab">
          歷史紀錄
        </v-tab>
      </v-tabs>

      <v-card-text class="ps-0 pe-0">
        <v-window v-model="tab">
          <!-- 當前黑名單 -->
          <v-window-item value="blacklist">
            <div id="toolBar">
              <SetupSearchBarPro
                :is-show-search-input="true"
                :is-show-multiple-select="true"
                :multiple-select="multiselect"
                @searchSubmitFn="searchBlacklist"
              />
              <CommonButtonAdd name="新增黑名單" @click="showBlacklistDlg()" />
            </div>

            <div class="tableStyle">
              <LazySetupBlacklistTable
                :table-data="blackLists"
                @editBlackList="editBlackListDlg"
                @deleteBlackList="deleteCheck"
                @refreshBlackList="refreshBlackList"
              />
            </div>
          </v-window-item>
          <!-- 歷史紀錄 -->
          <v-window-item value="history">
            <div id="toolBar">
              <SetupSearchBarPro
                :is-show-search-input="true"
                :is-show-multiple-select="true"
                :multiple-select="multiselect"
                @searchSubmitFn="searchBlacklist"
              />
              <CommonButtonAdd name="新增黑名單" @click="showBlacklistDlg()" />
            </div>
            <div class="tableStyle">
              <LazySetupBlacklistTable
                :table-data="blackLists"
                @editBlackList="editBlackListDlg"
                @deleteBlackList="deleteCheck"
                @refreshBlackList="refreshBlackList"
              />
            </div>
          </v-window-item>
        </v-window>
        <SetupBlacklistAddEditDlg
          :is-show="dlgBlackListVisible"
          :dialog-info="blacklistInfo"
          @closeDlg="closeBlackListDlg"
        />
        <CommonDeleteDlg
          v-model:dlgVisible="dlgVisible"
          :data="blacklistInfo.data"
          :sub-title="deleteSubTitle"
          :content="deleteContent"
          @deleteFn="deleteFn"
        />
      </v-card-text>
    </div>
  </div>
</template>

<script setup>
import {
  editBlackListFn,
  getBlackListsFn,
  deleteBlackListFn
} from '@/api/admin/blacklist'

import {
  getChannelsREQFn
} from '@/api/message/channels'

const customerInfo = useCustomerInfo()

const getChannelsREQ = () => {
  const params = {
    pageNum: '1',
    pageSize: '100'
  }

  const onResponse = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      multiselect.value[0].data = res.data.data
    }
  }
  getChannelsREQFn(params, onResponse)
}

const multiselect = ref([
  {
    name: '渠道',
    submitKey: 'channelId',
    itemTitle: 'channelName',
    itemValue: 'id',
    type: 'select',
    data: []
  },
  {
    name: 'IP',
    type: 'input',
    submitKey: 'ip'
  },
  {
    name: '操作人',
    type: 'select',
    submitKey: 'creator'
  },
  {
    name: '訪客名稱',
    type: 'input',
    submitKey: 'customerName'
  },
  {
    name: '創建時間',
    type: 'input',
    submitKey: 'created_at'
  },
  {
    name: '原因',
    type: 'input',
    submitKey: 'reason'
  }
])

const deleteFn = (item) => {
  deleteBlackList(item)
}

const tab = ref('blacklist')

const blackLists = ref([])

onBeforeMount(() => {
  customerInfo.blackProgressLoading = true
  refreshBlackList(1)
  getChannelsREQ()
})

const deleteSubTitle = ref('')

const deleteContent = ref('')

const searchBlacklist = (keyword) => {
  refreshBlackList(1, keyword.searchInput)
}

// 【黑名單】 -------------------------------------------------
const dlgBlackListVisible = ref(false)
const blacklistInfo = reactive({
  dlg: {
    type: 'add'
  },
  data: {
    id: '',
    ip: '',
    reason: '',
    expiredAt: ''
  }
})

const currentPage = ref(1)
const blacklistInput = ref('')
const historyInput = ref('')

// 切換Tab事件
const switchTab = () => {
  refreshBlackList(1)
  currentPage.value = 1
}

// 顯示 add / edit Dlg
const showBlacklistDlg = () => {
  blacklistInfo.dlg.type = 'add'
  blacklistInfo.data = {
    id: '',
    word: '',
    status: '',
    type: ''
  }
  dlgBlackListVisible.value = true
}

// 關閉 dlg
const closeBlackListDlg = () => {
  dlgBlackListVisible.value = false
  refreshBlackList()
}

// 編輯資料
const editBlackListDlg = (item) => {
  blacklistInfo.dlg.type = 'edit'
  blacklistInfo.data = item
  dlgBlackListVisible.value = true
}

// 編輯黑名單
const editBlackList = (item) => {
  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      refreshBlackList()
    }
  }
  editBlackListFn(item.id, item, onRes)
}

// 刪除黑名單
const dlgVisible = ref(false)
const deleteCheck = (item) => {
  if (item.ip) {
    deleteSubTitle.value = '刪除 ' + item.ip + ' 紀錄?'
    deleteContent.value = '該IP地址將被從黑名單移除，並重新允許訪問。您確定要刪除嗎？'
  } else {
    deleteSubTitle.value = '刪除 ' + item.customerName + ' 紀錄?'
    deleteContent.value = '該訪客將被從黑名單移除，並重新允許訪問。您確定要刪除嗎？'
  }

  dlgVisible.value = true
  blacklistInfo.data = item
}
const deleteBlackList = (item) => {
  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      refreshBlackList()
    }
  }
  deleteBlackListFn(item.id, onRes)
  dlgVisible.value = false
}

// 刷新黑名單
const refreshBlackList = async (page, search) => {
  // 判斷當前是哪一個頁簽
  const _isExpired = tab.value === 'history' ? 'true' : 'false'

  // 若有指定頁數則賦值,否則保持原值
  currentPage.value = page || currentPage.value

  let searchContent = {}
  // 若有搜索的值則賦值,否則保持原值
  searchContent = search !== undefined ? search : _isExpired ? blacklistInput.value : historyInput.value

  const params = {
    ...searchContent,
    pageNum: currentPage.value,
    pageSize: 20,
    isExpired: _isExpired
  }

  const onRes = ({ response }) => {
    const res = response._data

    if (res.code === 200) {
      customerInfo.blackProgressLoading = false
      blackLists.value = res.data
    }
  }

  await getBlackListsFn(params, onRes)
}

provide('provideInfo', {
  refreshBlackList
})
</script>

<style lang="scss" scoped>
@import "assets/styles/pages/setup.scss";
</style>
