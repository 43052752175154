
// 黑名單控制器
import { useRuntimeConfig } from '#app'
const { useCustomFetch } = useFetchBase()

const base = '/api/admin'
const modules = '/blackLists'
const path = base + modules

const getBlacklist = path + '/q' // 獲取黑名單列表

const addBlacklist = path // 添加黑名單

const blacklist = path + '/{0}' // 黑名單
/**
 * 獲取黑名單列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns 資料
 */
const getBlackListsFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getBlacklist, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}
/**
 * 添加黑名單
 * @param {Object} formData 表單提交
 * @param {Object} callback 回掉函數
 * @returns
 */
const addBlackListFn = (formData, callback) => { // 添加
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addBlacklist, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 編輯黑名單
 * @param {BigInteger} id 黑名單id
 * @param {Object} formData 表單資料
 * @param {Object} callback  回掉函數
 * @returns
 */
const editBlackListFn = (id, formData, callback) => { // 添加
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(blacklist, id)
  return useCustomFetch(api, {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除黑名單
 * @param {BigInt} id 黑名單id
 * @param {Object} callback 回調函數
 * @returns
 */
const deleteBlackListFn = (id, callback) => { // 刪除
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(blacklist, id)
  return useCustomFetch(api, {
    method: 'DELETE',
    onResponse: callback
  })
}

export {
  getBlackListsFn,
  addBlackListFn,
  editBlackListFn,
  deleteBlackListFn
}
