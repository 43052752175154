<template>
  <v-dialog
    :model-value="isShow"
    width="500"
    persistent
  >
    <v-form ref="form" v-model="addEditForm" @submit.prevent="submit(dataInfo)">
      <v-card rounded="lg">
        <!-- 彈窗標題 -->
        <v-card-title class="pt-5 d-flex justify-space-between align-center">
          <div class="text-h6 text-medium-emphasis ps-2 font-weight-bold ">
            {{ dialogInfo.dlg.type == 'add'? '新增':'編輯' }}黑名單
          </div>
          <v-btn
            icon="fa-solid fa-xmark"
            variant="text"
            @click="closeSensitiveDlg()"
          />
        </v-card-title>

        <!-- 彈窗內容 -->
        <v-card-text class="mt-4 mb-4">
          <div class="mb-4">
            <div class="text-subtitle-1">
              IP <span class="colorRed">*</span>
            </div>
            <v-text-field
              v-model="dataInfo.ip"
              type="text"
              :rules="[addEditRules]"
              density="compact"
              variant="outlined"
              placeholder="請輸入名稱"
              clearable
            />
          </div>
          <div class="mb-4">
            <div class="text-subtitle-1">
              拉黑期限 <span class="colorRed">*</span>
            </div>

            <v-radio-group v-model="radios" hide-details>
              <v-radio label="永久" value="permanent" color="#448AFF" />
              <v-radio value="fixed" color="#448AFF">
                <template #label>
                  <div class="d-flex justify-xl-center align-center">
                    <v-text-field
                      v-model="fiexdInputValue.day"
                      class="textCenter"
                      type="text"

                      variant="underlined"
                    />
                    <span class="text-subtitle-1 ms-2 me-2">天</span>
                    <v-text-field
                      v-model="fiexdInputValue.hour"
                      class="textCenter"
                      type="text"

                      variant="underlined"
                    />
                    <span class="text-subtitle-1 ms-2 me-2">時</span>
                    <v-text-field
                      v-model="fiexdInputValue.minute"
                      class="textCenter"

                      type="text"

                      variant="underlined"
                    />
                    <span class="text-subtitle-1 ms-2 me-2">分</span>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <div>
            <div class="text-subtitle-1">
              拉黑原因 <span class="colorRed">*</span>
            </div>
            <v-textarea
              v-model="dataInfo.reason"
              density="compact"
              hide-details="auto"
              variant="plain"
              class="borderStyle"
              maxlength="500"
              counter
              clearable
            />
          </div>
        </v-card-text>

        <!-- 彈窗底部按鈕 -->
        <v-card-actions class="my-2">
          <v-spacer />
          <v-btn
            class="text-none"
            rounded="xl"
            text="取消"
            @click="closeSensitiveDlg()"
          />

          <v-btn
            class="text-none me-4"
            color="blue-darken-1"
            :loading="loading"
            rounded="xl"
            text="確定"
            variant="flat"
            type="submit"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script setup>
import { addBlackListFn, editBlackListFn } from '~/api/admin/blacklist'
const prop = defineProps({
  isShow: Boolean,
  dialogInfo: {
    type: Object,
    default: () => {}
  }
})

const emit = defineEmits(['closeDlg'])

const radios = ref('permanent')
const fiexdInputValue = reactive({
  day: '',
  hour: '',
  minute: ''
})
const dataInfo = reactive({
  ip: '',
  customerId: '',
  reason: '',
  expiredAt: ''
})

watch(() => prop.isShow, (val) => {
  if (val) {
    if (prop.dialogInfo && prop.dialogInfo.dlg.type === 'edit') {
      // timestamp to str
      const data = prop.dialogInfo.data
      const durtaion = data.expirationDuration
      fiexdInputValue.day = secondsToDay(durtaion)
      fiexdInputValue.hour = secondsToHour(durtaion)
      fiexdInputValue.minute = secondsToMinute(durtaion)
      radios.value = data.expirationDuration === -1 ? 'permanent' : 'fixed'
      dataInfo.id = data.id
      dataInfo.ip = data.ip
      dataInfo.customerId = data.customerId
      dataInfo.reason = data.reason
    }
  }
})

// 防呆【必填】
const addEditRules = value => !!value || '必填'

// 防呆【submit btn】
const addEditForm = ref(false)
// loading
const loading = ref(false)
// 清除form
const form = ref('')
const reset = () => {
  form.value.reset()
}
// 關閉 dialog
const closeSensitiveDlg = (getValue) => {
  emit('closeDlg', {
    close: false,
    getDeptList: getValue
  })
  reset() // 清除表格
}

const submit = (info) => {
  if (!addEditForm.value) { return }
  loading.value = true
  const formData = {
    ip: info.ip,
    reason: info.reason,
    expirationDuration: radios.value === 'permanent' ? -1 : dayHourMinuteToSeconds(fiexdInputValue.day, fiexdInputValue.hour, fiexdInputValue.minute)
  }
  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      closeSensitiveDlg(true) // 關閉並更新列表
    }
    loading.value = false
  }
  if (prop.dialogInfo.dlg.type === 'add') {
    addBlackListFn(formData, onRes)
  } else {
    const id = info.id
    editBlackListFn(id, formData, onRes)
  }
}
</script>

    <style lang="scss" scoped>
      .v-card-text {
        padding: 0 2rem 2rem 2rem;
      }
      .textCenter{
        :deep(.v-field__input){
          text-align: center;
        }
      }
      .borderStyle {
    :deep(.v-input__control) {
      border: 1px solid #c7c7c7;
      border-radius: 3px;
      padding: 5px 15px;
    }
  }
    </style>
