<template>
  <!-- 下拉選單(多選) -->
  <div id="multipleSelect">
    <span>{{ label + '：' }}</span>

    <v-select
      v-model="select"
      class="mselect"
      :items="multipleSelect"
      :item-title="title"
      :item-value="itemValue"
      clearable
      hide-details=""
      density="compact"
      variant="outlined"
      @update:model-value="getSelect()"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  submitKey: {
    type: String,
    default: ''
  },

  label: {
    type: String,
    default: ''
  },
  multipleSelect: {
    type: Array,
    default: () => []
  },
  title: {
    type: String,
    default: ''
  },
  itemValue: {
    type: String,
    default: ''
  }

})

const select = ref() // 選到的值
const emit = defineEmits(['selectFn'])
const getSelect = () => {
  emit('selectFn', {
    submitKey: props.submitKey,
    selectedValue: select.value
  })
}
</script>

  <style lang="scss" scoped>
    #multipleSelect {
      display: flex; align-items: center;
      border-radius:4px;
    }
    .mselect{
      background-color: #f5f5f5;
      border-radius: 5px;
    }
  </style>
